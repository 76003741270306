import { createEffect, createEvent, createStore } from 'effector';
import { ApiError } from 'api/errors/apiError';
import { authorize, unauthorize } from 'api';
import api from 'api/request/auth';
import { ApiResponse } from 'api/types';
import { AuthLoginForm } from 'api/types/auth';
import jwt_decode from 'jwt-decode';
// import {ApiFailure} from "api/types/api-status";
// import {authorize} from "api/instance";
// import {fetchAuthInfoFx} from "state/auth/info";
// import {fetchCompanyListFx} from "state/company";

export interface AuthState {
  token: string;
  role: string;
  email: string;
  modules: string;
  name: string;
  authError: string | null;
  // tokenExp: number;
  // refreshToken: string;
  // refreshTokenExp: number;
  // isFetching: boolean;
}

const initStore: AuthState = {
  token: '',
  role: '',
  name: '',
  email: '',
  modules: '',
  authError: null,
  // tokenExp: 0,
  // refreshToken: '',
  // refreshTokenExp: 0,
  // isFetching: false,
};

export const setModules = createEvent<string>();
export const setToken = createEvent<string>();
export const setRole = createEvent<string>();
export const setAuthError = createEvent();
export const logout = createEvent();

export const loginFx = createEffect<
  AuthLoginForm,
  ApiResponse<{ name: string; token: string; email: string}>,
  ApiError<any>
>({
  handler: async (data: AuthLoginForm) => {
    return await api.login(data);
  },
});
export const setRemberMe = createEvent<boolean>();

export const $remeberMe = createStore<boolean>(false).on(setRemberMe, (_, remeber) => remeber);

export const $auth = createStore<AuthState>(initStore)
  .on(setToken, (state, token) => ({
    ...state,
    token,
  }))
  .on(setRole, (state, role) => ({
    ...state,
    role,
  }))
  .on(setModules, (state, modules) =>({
    ...state,
    modules
  }))
  .on(setAuthError, (state) => ({
    ...state,
    authError: null,
  }))
  .on(loginFx.pending, (state) => ({ ...state }))
  .on(loginFx.doneData, (state, response) => {
    if (response.error) {
      return {
        ...state,
        authError: response.error.message,
      };
    }
    const { token, name, email } = response.data;
    localStorage.setItem('rememberMe', String($remeberMe.getState()));
    if ($remeberMe.getState()) {
      localStorage.setItem('accessRole', (jwt_decode(token) as any).role);
      localStorage.setItem('accessToken', token);
    } else {
      sessionStorage.setItem('accessRole', (jwt_decode(token) as any).role);
      sessionStorage.setItem('accessToken', token);
    }
    authorize(token);
    return {
      ...state,
      token,
      name,
      email,
      modules:(jwt_decode(token) as any).modules,
      role: (jwt_decode(token) as any).role,
    };
  });
logout.watch(() => {
  unauthorize();
  localStorage.clear();
  sessionStorage.clear();
  // @ts-ignore
  window.location = '/login';
});

///-----------------------

$auth.reset(logout);
