import React, { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { authorize } from 'api';
import { setModules, setRole, setToken } from 'state/auth';
import { authRequired, guestOnly } from './components/auth';
import 'antd/dist/antd.min.css';
import './styles/ant/index.scss';
import jwt_decode from 'jwt-decode';

const LoginPage = guestOnly(lazy(() => import('routes/login')));
const RemindPage = guestOnly(lazy(() => import('routes/remind/components/Page')));
const MainPage = authRequired(lazy(() => import('routes/main')));

function App() {
  useMemo(
    () => {
      // const token = getItem('accessToken');
      let token: string | null;
      let role: string | null;
      const remember = localStorage.getItem('rememberMe');
      if (remember === 'true') {
        token = localStorage.getItem('accessToken');
        role = localStorage.getItem('accessRole');
      } else {
        token = sessionStorage.getItem('accessToken');
        role = sessionStorage.getItem('accessRole');
      }
      if (token) {
        setModules(((jwt_decode(token)) as any).modules)
        authorize(token);
        setToken(token);
        setRole(role ? role : '');
      }
    },
    [
      /*dispatch*/
    ],
  );
  return (
    <BrowserRouter>
      <Suspense fallback={<div>загрука..</div>}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/remind" component={RemindPage} />
          <Route path="/" component={MainPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
